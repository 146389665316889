import React, { useState, useEffect } from "react";
import { Box, Container, Typography, useTheme, useMediaQuery } from '@mui/material';
import {  useNavigate } from "react-router-dom";

export default function Footer() {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const navigate = useNavigate();
  const [state, setState] = useState({
    mobileView: false,
  });

  useEffect(() => {
    const setResponsiveness = () => {
      setState({
        mobileView: window.innerWidth < 1220,
      });
    };

    setResponsiveness();
    window.addEventListener("resize", setResponsiveness);

    return () => {
      window.removeEventListener("resize", setResponsiveness);
    };
  }, []);

  return (
    <Box
      sx={{
        position: (navigate.pathname === "/" || navigate.pathname === "/forget" || navigate.pathname === "/verify-otp" || navigate.pathname === "/reset-password") ? "relative" : "fixed",
        bottom: (navigate.pathname === "/" || navigate.pathname === "/forget" || navigate.pathname === "/verify-otp" || navigate.pathname === "/reset-password") ? "19px" : "0",
        width: "100%",
        zIndex: "999",
        backgroundColor: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "30px"
      }}
    >
      <Container maxWidth="lg" sx={{ textAlign: "center" }}>
        <Typography variant="h6" color="primary" sx={{ fontSize: isXs ? "11px" : "inherit" }}>
          Admin panel Developed By Mobiloitte Technologies
        </Typography>
      </Container>
    </Box>
  );
}
