import { lazy } from "react";
import LoginLayout from "./layouts/LoginLayout";
import DashboardLayout from "./layouts/DashboardLayout/index.js";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: LoginLayout,
    component: lazy(() => import("./views/pages/AuthFlow/Login.js")),
  },
  {
    exact: true,
    path: "/forgot",
    layout: LoginLayout,
    component: lazy(() => import("./views/pages/AuthFlow/ForgotPassword.js")),
  },
  {
    exact: true,
    path: "/otpVerification",
    layout: LoginLayout,
    component: lazy(() => import("./views/pages/AuthFlow/OTPVerification.js")),
  },
  {
    exact: true,
    path: "/resetPassword",
    layout: LoginLayout,
    component: lazy(() => import("./views/pages/AuthFlow/ResetPassword.js")),
  },
  {
    exact: true,
    path: "/dashboard",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/Dashboard.js")),
    guard: true,
  },
  {
    exact: true,
    path: "/usermanagement/customer",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/UserManagement/CustomerManagement/CustomerListing.js"
      )
    ),
    guard: true,
  },
  {
    exact: true,
    path: "/usermanagement/customer/viewcustomer/:userId",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/UserManagement/CustomerManagement/ViewCustomer.js"
      )
    ),
    guard: true,
  },
  {
    exact: true,
    path: "/usermanagement/business",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/UserManagement/BusinessManagement/BusinessListing.js"
      )
    ),
    guard: true,
  },
  {
    exact: true,
    path: "/usermanagement/business/viewbusiness/:userId",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/UserManagement/BusinessManagement/ViewBusiness/ViewBusiness.js"
      )
    ),
    guard: true,
  },
  {
    exact: true,
    path: "/usermanagement/business/addbusinessaccount",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/UserManagement/BusinessManagement/AddBusinessAccount.js"
      )
    ),
    guard: true,
  },
  {
    exact: true,
    path: "/usermanagement/business/editbusinessaccount/:userId",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/UserManagement/BusinessManagement/EditBusinessAcount.js"
      )
    ),
    guard: true,
  },
  {
    exact: true,
    path: "/usermanagement/business/viewnewbussiness",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/UserManagement/BusinessManagement/ViewNewRequest/ViewRequestListing.js"
      )
    ),
    guard: true,
  },
  {
    exact: true,
    path: "/usermanagement/business/viewnewbussiness/viewdetails",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/UserManagement/BusinessManagement/ViewNewRequest/viewNewRequest.js"
      )
    ),
    guard: true,
  },
  {
    exact: true,
    path: "/usermanagement/business/viewnewbussiness/viewrejectedetails",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/UserManagement/BusinessManagement/ViewNewRequest/ViewRejectedRequest.js"
      )
    ),
    guard: true,
  },
  {
    exact: true,
    path: "/usermanagement/subadmin",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/UserManagement/SubadminManagement/SubAdminListing.js"
      )
    ),
    guard: true,
  },
  {
    exact: true,
    path: "/usermanagement/subadmin/addsubadmin",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/UserManagement/SubadminManagement/AddSubAdmin.js"
      )
    ),
    guard: true,
  },
  {
    exact: true,
    path: "/usermanagement/subadmin/editsubadmin",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/UserManagement/SubadminManagement/EditSubAdmin.js"
      )
    ),
    guard: true,
  },
  {
    exact: true,
    path: "/usermanagement/subadmin/viewsubadmin",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/UserManagement/SubadminManagement/ViewSubAdmin.js"
      )
    ),
    guard: true,
  },
  {
    exact: true,
    path: "/prefrencemenu",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/PreferencesMenuManagement/PrefrenceMenu.js"
      )
    ),
    guard: true,
  },
  {
    exact: true,
    path: "/prefrencemenu/addcategory",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/PreferencesMenuManagement/Category/AddCategory.js"
      )
    ),
    guard: true,
  },
  {
    exact: true,
    path: "/prefrencemenu/editcategory/:tagId",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/PreferencesMenuManagement/Category/EditCategory.js"
      )
    ),
    guard: true,
  },
  {
    exact: true,
    path: "/prefrencemenu/addcuisine",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/PreferencesMenuManagement/Cuisine/Addcuisine.js"
      )
    ),
    guard: true,
  },
  {
    exact: true,
    path: "/prefrencemenu/editcuisine/:cuisineId",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/PreferencesMenuManagement/Cuisine/EditCuisine.js"
      )
    ),
    guard: true,
  },
  {
    exact: true,
    path: "/prefrencemenu/addfood",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/PreferencesMenuManagement/Food/AddFood.js"
      )
    ),
    guard: true,
  },
  {
    exact: true,
    path: "/prefrencemenu/editfood/:foodId",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/PreferencesMenuManagement/Food/EditFood.js"
      )
    ),
    guard: true,
  },

  {
    exact: true,
    path: "/prefrencemenu/adddish",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/PreferencesMenuManagement/DishesMenu/AddDishes.js"
      )
    ),
    guard: true,
  },
  {
    exact: true,
    path: "/prefrencemenu/editdish/:dishId",

    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/PreferencesMenuManagement/DishesMenu/EditDishes.js"
      )
    ),
    guard: true,
  },
  {
    exact: true,
    path: "/prefrencemenu/editfacilities/:facilityId",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/PreferencesMenuManagement/Facilities/EditFacilities.js"
      )
    ),
    guard: true,
  },
  {
    exact: true,
    path: "/prefrencemenu/addfacilities",

    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/PreferencesMenuManagement/Facilities/AddFacilities.js"
      )
    ),
    guard: true,
  },
  {
    exact: true,
    path: "/prefrencemenu/adddrink",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/PreferencesMenuManagement/DrinkMenu/AddDrink.js"
      )
    ),
    guard: true,
  },
  {
    exact: true,
    path: "/prefrencemenu/editdrink/:dishId",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/PreferencesMenuManagement/DrinkMenu/EditDrinks.js"
      )
    ),
    guard: true,
  },
  {
    exact: true,
    path: "/prefrencemenu/addfoodoptions",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/PreferencesMenuManagement/ActivityOptions/AddActivityOptions.js"
      )
    ),
    guard: true,
  },
  {
    exact: true,
    path: "/prefrencemenu/editfoodoptions",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/PreferencesMenuManagement/ActivityOptions/EditActivityOptions.js"
      )
    ),
    guard: true,
  },
  {
    exact: true,
    path: "/venuelisting",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/Dashboard/VenueManagement/VenueListing.js")
    ),
    guard: true,
  },
  {
    exact: true,
    path: "/venuelisting/viewvenue/:venueId",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/Dashboard/VenueManagement/ViewVenue.js")
    ),
    guard: true,
  },
  {
    exact: true,
    path: "/venuelisting/viewvenueDetails/:venueId",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/Dashboard/VenueManagement/VenueDetails/GeneralInfo.js")
    ),
    guard: true,
  },
  {
    exact: true,
    path: "/venuelisting/viewnewvenue",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/VenueManagement/NewVenueRequest/NewVenueRequestListing.js"
      )
    ),
    guard: true,
  },
  {
    exact: true,
    path: "/venuelisting/viewvenue/viewoffer",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/SpontaneousOfferManagement/ViewSpontaneousOffer.js"
      )
    ),
    guard: true,
  },
  {
    exact: true,
    path: "/spontaneouslisting",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/SpontaneousOfferManagement/SpontaneousListing.js"
      )
    ),
    guard: true,
  },
  {
    exact: true,
    path: "/spontaneouslisting/addspontaneousoffer",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/SpontaneousOfferManagement/AddSpontaneousOffer.js"
      )
    ),
    guard: true,
  },
  {
    exact: true,
    path: "/spontaneouslisting/viewspontaneousoffer",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/SpontaneousOfferManagement/ViewSpontaneousOffer.js"
      )
    ),
    guard: true,
  },
  {
    exact: true,
    path: "/offerlisting",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/Dashboard/OfferManagement/OfferListing.js")
    ),
    guard: true,
  },
  {
    exact: true,
    path: "/offerlisting/viewoffer/:offerId",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/Dashboard/OfferManagement/ViewOffer.js")
    ),
    guard: true,
  },
  {
    exact: true,
    path: "/offerlisting/viewnewoffer",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/OfferManagement/NewOfferRequestListing/NewOfferRequestLising.js"
      )
    ),
    guard: true,
  },
  {
    exact: true,
    path: "/offerlisting/viewnewoffer/viewrejectedoffer",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/OfferManagement/NewOfferRequestListing/ViewRejectedOffer.js"
      )
    ),
    guard: true,
  },
  {
    exact: true,
    path: "/offerlisting/viewnewoffer/viewpendingoffer",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/OfferManagement/NewOfferRequestListing/ViewPendingOffer.js"
      )
    ),
    guard: true,
  },
  {
    exact: true,
    path: "/contentlisting",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/StaticContentManagement/StaticContentListing.js"
      )
    ),
    guard: true,
  },
  {
    exact: true,
    path: "/contentlisting/privacypolicy",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/StaticContentManagement/PrivacyPolicy/PrivacyPolicy.js"
      )
    ),
    guard: true,
  },
  {
    exact: true,
    path: "/contentlisting/editPrivacyPolicy",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/StaticContentManagement/PrivacyPolicy/EditPrivacyPolicy.js"
      )
    ),
    guard: true,
  },
  {
    exact: true,
    path: "/contentlisting/aboutus",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/StaticContentManagement/AboutUs/AboutUs.js"
      )
    ),
    guard: true,
  },
  {
    exact: true,
    path: "/contentlisting/editaboutus",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/StaticContentManagement/AboutUs/EditAboutUs.js"
      )
    ),
    guard: true,
  },
  {
    exact: true,
    path: "/contentlisting/tc",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/StaticContentManagement/TermsAndCondition/TermsAndCondition.js"
      )
    ),
    guard: true,
  },
  {
    exact: true,
    path: "/contentlisting/edittc",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/StaticContentManagement/TermsAndCondition/EditTermAndCondition.js"
      )
    ),
    guard: true,
  },
  {
    exact: true,
    path: "/contentlisting/faq",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/Dashboard/StaticContentManagement/FAQ/FAQ.js")
    ),
    guard: true,
  },
  {
    exact: true,
    path: "/contentlisting/faq/addfaq",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/Dashboard/StaticContentManagement/FAQ/AddFQA.js")
    ),
    guard: true,
  },
  {
    exact: true,
    path: "/contentlisting/faq/editfaq/:faqId",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/Dashboard/StaticContentManagement/FAQ/EditFAQ.js")
    ),
    guard: true,
  },
  {
    exact: true,
    path: "/feedbacklisting",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/Dashboard/FeedbackMangement.js/FeedbackListing.js")
    ),
    guard: true,
  },
  {
    exact: true,
    path: "/feedbacklisting/viewfeedback",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/Dashboard/FeedbackMangement.js/ViewFeedBack.js")
    ),
    guard: true,
  },
  {
    exact: true,
    path: "/profile",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Setting/ManageProfile.js")),
    guard: true,
  },
  {
    exact: true,
    path: "/profile/editprofile",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Setting/EditProfile.js")),
    guard: true,
  },
  {
    exact: true,
    path: "/profile/changepassword",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Setting/ChangePassword.js")),
    guard: true,
  },
];


