import axios from "axios";
import ApiConfig from "./APIConfig";
import { toast } from "react-toastify";
import CryptoJS from "crypto-js";
export const postDataHandler = async (endPoint, data) => {
  try {
    console.log(data, "valuesvalues");
    const res = await axios({
      method: "POST",
      url: ApiConfig[endPoint],
      data: data,
    });

    if (res?.data?.responseCode === 200) {
      return res.data;
    } else {
      toast.error(res?.data?.message);
      return res;
    }
  } catch (error) {
    console.log("error: ", error);
    if (error.response) {
      return error.response;
    } else {
      return error;
    }
  }
};

export const postDataHandlerWithToken = async (
  endPoint,
  data,
  authorizationHeader = ""
) => {
  try {
    const token = authorizationHeader || sessionStorage.getItem("token");
    console.log("gdfgdsfdsfds", token);
    const res = await axios({
      method: "POST",
      url: ApiConfig[endPoint],
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      data: data,
    });
    if (res?.data?.responseCode === 200) {
      return res.data;
    } else {
      toast.error(res?.data?.responseMessage);
      return res;
    }
  } catch (error) {
    console.log("error: ", error);
    toast.error(error?.response?.data?.responseMessage);
    return error.response;
  }
};

export const putDataHandler = async (endPoint, data) => {
  try {
    const res = await axios({
      method: "POST",
      url: ApiConfig[endPoint],
      data: data,
    });
    if (res.data.responseCode === 200) {
      return res.data;
    } else {
      toast.error(res?.data?.message);
      return res;
    }
  } catch (error) {
    console.log(error);
    if (error.response) {
      return error.response;
    }
  }
};

export const putDataHandlerWithToken = async (endPoint, data, params) => {
  try {
    const res = await axios({
      method: "PUT",
      url: ApiConfig[endPoint],
      headers: {
        token: sessionStorage.getItem("token"),
      },
      data: data,
      params: params,
    });
    if (res.data.responseCode === 200) {
      return res.data;
    } else {
      return res;
    }
  } catch (error) {
    console.log(error);
    if (error.response) {
      return error.response;
    }
  }
};

export const deleteDataHandler = async (endPoint, query, body) => {
  try {
    const res = await axios({
      method: "DELETE",
      url: ApiConfig[endPoint],
      headers: {
        token: sessionStorage.getItem("token"),
      },
      params: query,
      data: body,
    });
    if (res.data.responseCode === 200) {
      return res.data;
    } else {
      return res;
    }
  } catch (error) {
    console.log(error);
  }
};

export const patchDataHandler = async (endPoint, data) => {
  try {
    const res = await axios({
      method: "PATCH",
      url: ApiConfig[endPoint],
      data: data,
    });
    if (res.data.responseCode === 200) {
      toast.success(res.data.responseMessage);
      return res.data;
    } else {
      toast.error(res.data.responseMessage);
      return res;
    }
  } catch (error) {
    if (error.response) {
      return error.response;
    }
    console.log(error);
  }
};

export const patchTokenDataHandler = async (endPoint, data) => {
  try {
    const res = await axios({
      method: "PUT",
      url: ApiConfig[endPoint],
      headers: {
        token: sessionStorage.getItem("token"),
      },
      data: data,
    });
    if (res.data.responseCode === 200) {
      return res.data;
    } else {
      toast.error(res?.data?.responseMessage);
      return res;
    }
  } catch (error) {
    if (error.response) {
      return error.response;
    }
    console.log(error);
  }
};
export const deleteDataHandlerWithoutToken = async (endPoint, query) => {
  try {
    const res = await axios({
      method: "DELETE",
      url: ApiConfig[endPoint],

      params: query,
    });
    if (res.responseCode === 200) {
      return res.message;
    } else {
      return res;
    }
  } catch (error) {
    console.log(error);
  }
};
export const getDataHandler = async (endPoint, query, data) => {
  try {
    const res = await axios({
      method: "GET",
      url: ApiConfig[endPoint],
      data: data,
      params: query,
    });

    if (res?.data?.responseCode === 200) {
      return res.data;
    } else {
      toast.error(res?.data?.message);
      return res;
    }
  } catch (error) {
    if (error.response) {
      return error.response;
    } else {
      return error;
    }
  }
};
export const getDataHandlerWithToken = async (endPoint, query, data) => {

  try {
    const res = await axios({
      method: "GET",
      url: ApiConfig[endPoint],
      headers: {
        token: sessionStorage.getItem("token"),
      },
      data: data,
      params: query,
    });

    if (res?.data?.responseCode === 200) {
      return res.data;
    } else {
      toast.error(res?.data?.message);
      return res;
    }
  } catch (error) {
    console.log("error: ", error);
    if (error.response) {
      return error.response;
    } else {
      return error;
    }
  }
};

// Ensure the key is 32 bytes long (for AES-256)
const padKey = (key) => {
  const keyUtf8 = CryptoJS.enc.Utf8.parse(key);
  if (keyUtf8.sigBytes < 32) {
    const paddedKey = CryptoJS.lib.WordArray.create(
      keyUtf8.words.concat(
        CryptoJS.lib.WordArray.random(32 - keyUtf8.sigBytes)
      ),
      32
    );
    return paddedKey;
  }
  return CryptoJS.lib.WordArray.create(keyUtf8.words.slice(0, 32), 32);
};

// Encryption function
export const encrypt = (text, key) => {
  const iv = CryptoJS.lib.WordArray.random(16);
  const paddedKey = padKey(key);
  const encrypted = CryptoJS.AES.encrypt(text, paddedKey, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return (
    iv.toString(CryptoJS.enc.Hex) +
    ":" +
    encrypted.ciphertext.toString(CryptoJS.enc.Hex)
  );
};

// Decryption function
export const decrypt = (encryptedText, key) => {
  const [ivHex, encryptedHex] = encryptedText.split(":");
  const iv = CryptoJS.enc.Hex.parse(ivHex);
  const paddedKey = padKey(key);
  const encryptedWordArray = CryptoJS.enc.Hex.parse(encryptedHex);

  const decrypted = CryptoJS.AES.decrypt(
    { ciphertext: encryptedWordArray },
    paddedKey,
    {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  );
  return decrypted.toString(CryptoJS.enc.Utf8);
};
