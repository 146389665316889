import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AuthContext } from "../context/Auth";

export default function AuthGuard({ children }) {
  const location = useLocation();
  const auth = useContext(AuthContext);

  const nonGuardedPaths = ["/login", "/"];
  const guardedPaths = [
    "/profile",
    "/dashboard",
    "/usermanagement/customer",
    "/usermanagement/customer/viewcustomer/:userId",
    "/usermanagement/business",
    "/usermanagement/business/viewbusiness/:userId",
    "/usermanagement/business/addbusinessaccount",
    "/usermanagement/business/editbusinessaccount/:userId",
    "/usermanagement/business/viewnewbussiness",
    "/usermanagement/business/viewnewbussiness/viewdetails",
    "/usermanagement/business/viewnewbussiness/viewrejectedetails",
    "/usermanagement/subadmin",
    "/usermanagement/subadmin/addsubadmin",
    "/usermanagement/subadmin/editsubadmin",
    "/usermanagement/subadmin/viewsubadmin",
    "/prefrencemenu",
    "/prefrencemenu/addcategory",
    "/prefrencemenu/editcategory/:tagId",
    "/prefrencemenu/addcuisine",
    "/prefrencemenu/editcuisine/:cuisineId",
    "/prefrencemenu/addfood",
    "/prefrencemenu/editfood/:foodId",
    "/prefrencemenu/adddish",
    "/prefrencemenu/editdish/:dishId",
    "/prefrencemenu/editfacilities/:facilityId",
    "/prefrencemenu/addfacilities",
    "/prefrencemenu/adddrink",
    "/prefrencemenu/editdrink/:dishId",
    "/prefrencemenu/addfoodoptions",
    "/prefrencemenu/editfoodoptions",
    "/venuelisting",
    "/venuelisting/viewvenue/:venueId",
    "/venuelisting/viewvenueDetails/:venueId",
    "/venuelisting/viewnewvenue",
    "/venuelisting/viewvenue/viewoffer",
    "/spontaneouslisting",
    "/spontaneouslisting/addspontaneousoffer",
    "/spontaneouslisting/viewspontaneousoffer",
    "/offerlisting",
    "/offerlisting/viewoffer/:offerId",
    "/offerlisting/viewnewoffer",
    "/offerlisting/viewnewoffer/viewrejectedoffer",
    "/offerlisting/viewnewoffer/viewpendingoffer",
    "/contentlisting",
    "/contentlisting/privacypolicy",
    "/contentlisting/editPrivacyPolicy",
    "/contentlisting/aboutus",
    "/contentlisting/editaboutus",
    "/contentlisting/tc",
    "/contentlisting/edittc",
    "/contentlisting/faq",
    "/contentlisting/faq/addfaq",
    "/contentlisting/faq/editfaq/:faqId",
    "/feedbacklisting",
    "/feedbacklisting/viewfeedback",
    "/profile",
    "/profile/editprofile",
    "/profile/changepassword",
  ];

  // If not authenticated and trying to access a guarded path
  console.log("auth.userLoggedIn: ", auth.userLoggedIn);
  if (!auth.userLoggedIn && guardedPaths.includes(location.pathname)) {
    localStorage.setItem("lastPath",location.pathname)
    return <Navigate to="/" replace />;
  }

  // If authenticated and accessing a non-guarded path
  if (nonGuardedPaths.includes(location.pathname)) {
    if (localStorage.getItem("userRole") === "PAT") {
      return <Navigate to="/patient-dashboard" replace />;
    }
    if (localStorage.getItem("userRole") === "DOC") {
      return <Navigate to="/doctor-appointment" replace />;
    }
  }

  return <>{children}</>;
}




