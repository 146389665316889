import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import { getDataHandlerWithToken } from "../config/services";

export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("token", accessToken);
    axios.defaults.headers.common.Authorization = `${accessToken}`;
  } else {
    localStorage.removeItem("token");
    delete axios.defaults.headers.common.Authorization;
  }
};

function checkLogin() {
  const accessToken = window.localStorage.getItem("token");
  console.log("accessToken: ", accessToken);
  return accessToken ? true : false;
}

export default function AuthProvider(props) {
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [userData,setUserData] = useState({});

  const handleUserData = async () =>{
    const res = await getDataHandlerWithToken("getProfile")
    if(res?.responseCode === 200){
      setUserData(res?.result)
    }
  }

  useEffect(()=>{
    const isToken = checkLogin()
    console.log("userData?.userType: ", userData);
    if(isToken && !userData?.userType){
      handleUserData()
    }else{
      setUserData({})
    }
  },[isLogin])

  let data = {
    userLoggedIn: isLogin,
    setIsLogin,
    userData,
    userLogIn: (type, data) => {
      setSession(data);
      setIsLogin(type);
    },
  };

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
