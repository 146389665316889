import React from "react";
import {
  Container,
  Box,
  Typography,
  AppBar,
  Toolbar,
  CssBaseline,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import LogoImage from "../assets/MainLogoPortal.png";

const Header = styled(AppBar)(({ theme }) => ({
  background: "#fff",
  position: "sticky",
  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
}));

const Footer = styled(Box)(({ theme }) => ({
  background: "#fff",
  padding: theme.spacing(2),
  textAlign: "center",
  boxShadow: "0px -4px 6px rgba(0, 0, 0, 0.1)",
}));

const Content = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(2),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const LoginLayout = ({ children }) => {
  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <CssBaseline />
      <Header>
        <Toolbar>
          {/* <Typography variant="h6" color="#FFFFFF" style={{ flexGrow: 1 }}>
            Cambeo
          </Typography> */}
          {/* <Box
            sx={{
              display: "flex",
              alignItems: "center",
              // width: "100%",
            }}
          > */}
          <img height={"50px"} src={LogoImage} />
          {/* </Box> */}
        </Toolbar>
      </Header>
      <Content>
        <Container maxWidth="lg" sx={{ width: "100%", maxWidth: "1600px" }}>
          {children}
        </Container>
      </Content>
      <Footer>
        <Typography variant="body2" color="#E31B54">
          © {new Date().getFullYear()} Admin panel Developed By Mobiloitte
          Technologies
        </Typography>
      </Footer>
    </Box>
  );
};

export default LoginLayout;
