import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import LogoImage from "../assets/MainLogoPortal.png";

const Root = styled("div")(({ theme }) => ({
  alignItems: "center",
  backgroundColor: "#ffffff",
  display: "flex",
  flexDirection: "column",
  height: "100vh",
  justifyContent: "center",
  zIndex: 2000,
}));

const ProgressBar = styled("div")({
  height: "3px",
});

export default function PageLoading() {
  return (
    <Root>
      <Box
        sx={{
          backgroundColor: "#FFFFFF",
          padding: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* <Typography 
          variant="h2" 
          color= 'primary'
          sx={{
           
            textAlign: 'center',
            fontWeight: 'bold',
          }}
        >
          Cambeo
        </Typography> */}
        <img height={"50px"} src={LogoImage} />
      </Box>
      <ProgressBar />
    </Root>
  );
}
